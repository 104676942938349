import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom' //Link nos permite crear un acceso a otras componentes
import ServicesPostventaLogo from '../images/ServicesPostventaLogo.PNG'
import {Showafterloged} from './hidden'
import {GetSkuList, GetCategorias, Conect, SLCred, GetRetaillist,GetTallerlist } from './dbfirebase'
import { useDispatch, useSelector } from 'react-redux';
import { setProducts } from '../store/productInfo/productDataSlice';
import { setCategorias } from '../store/productInfo/categoriasDataSlice';
import {useAuth} from "../context/authcontext";
import {useGeneral} from '../context/generalContext'
import { addOrdersInfo } from '../store/orderInfo/ordersInfoSlices';
import { addUserInfo } from '../store/userInfo/userInfoSlices';
import { addSapInfo } from '../store/SapInfo/SapInfoSlice'
import { addRetail } from '../store/orderInfo/retailInfo'

import {db} from "../components/firebase";
import {doc, getDoc, setDoc, addDoc, collection, 
    onSnapshot, query, deleteDoc, updateDoc, 
    arrayUnion, FieldValue, serverTimestamp, 
    getDocs, orderBy, startAt, endAt, where, FieldPath, limit} from "firebase/firestore";
import { addTaller } from '../store/orderInfo/tallerInfo'
import { ExpandNavBar } from './expandNavBar'


export const Navbar = () => {

  const {user} = useAuth();
  // const [dato, setDatos] = useState('')
  const {userData, Sl} = useGeneral()
  const dispatch = useDispatch();
  const userdatos = useSelector(state => state.userInfo)

  const datacred = SLCred()
  
  const perfil = async () => {
    const dato = await userData
    //skick(dato)
    dispatch(addUserInfo(dato))
  }

  //agregar funcion que trae los datos de retails
  const data = async() =>{ 
    const retail = await GetRetaillist()
    dispatch(addRetail(retail))
}

const orderTaller = async () => {
  try {
    const taller = await GetTallerlist();
 
    // Elimina la propiedad TimeStamp del array taller para pasarlo a un formato serializable
     const tallerWithoutTimeStamp = taller.map(item => {
      const { TimeStamp, ...rest } = item;

      // Asigna 'no asignado' a las propiedades undefined
      const updatedItem = Object.entries(rest).reduce((acc, [key, value]) => {
        acc[key] = value !== undefined ? value : 'no asignado';
        return acc;
      }, {});
    // console.log(taller)
      return updatedItem;
    });

  //  console.log(tallerWithoutTimeStamp)
    dispatch(addTaller(tallerWithoutTimeStamp));
  } catch (error) {
    console.error(error);
  }
};


  
  const GetSku = async() =>{
    const Skus = await GetSkuList()
    if (Skus){
        const products = Skus.sku;
        dispatch(setProducts(products));
    }
  }

  const Categoria = async() =>{
    const cat = await GetCategorias()
    const categ = cat.filter(objeto => Object.keys(objeto)[0] !== "Categoria")
    dispatch(setCategorias(categ))
  }


  // Testeamos colocar la llamada de datos de ordenes en el navbar Esto sirve para los repuestos al escanear, verificar si lo podemos mover al componente despacho.js
  useEffect(()=>{ 
      const actualdate = new Date()
      const prevdate = new Date(actualdate)
      prevdate.setDate(actualdate.getDate() - 180)
      let unsubscribe = null
      const fetchData = async () => {
          const cliente = userdatos
          try {
              const docRef = query(collection(db, 'Ordenes'), where("TimeStamp", ">", prevdate));
              unsubscribe =  onSnapshot(docRef, (query) => {
                  let dato = [];
                  query.forEach(doc => {
                      const d = doc.data()
                      const i = {'Id': doc.id}
                      
                      if (d.Cliente != (cliente[0]?cliente[0].Taller:'')){
                          
                          dato.push({...d, ...i})
                      } 
                  });
                  // showNotification()
                  
                  const data = dato.map(item => 
                    ({...item,
                    TimeStamp:item.TimeStamp.seconds}))
                    
                  dispatch(addOrdersInfo(data));
              })
          } catch (error) {
              console.error(error)
          }
      }
      fetchData()
      
      return () => {
          if (unsubscribe){
              unsubscribe()
          }
      }
  },[])

    // const conectToSap = async () => {
    //   console.log(userdatos)
    //   if (datacred) { //Agregar condicion para conectar a sap de acuerdo al valor SAP en userdatos.

    //       // dispatch(addSapState('Conectando'))
    //       try {
    //           // TESTING: para produccion =>"datacred.CompanyDB" / testing => "datacred.CompanyDB_Test"
    //           let status = await Conect(datacred.CompanyDB, datacred.UserName, datacred.Password, datacred.BaseURL, Sl)
    //           //let status = await Conect(datacred.CompanyDB_Test, datacred.UserName, datacred.Password, datacred.BaseURL, Sl)

    //           if (status === 200) {
    //               dispatch(addSapInfo('SAP'))
    //               // dispatch(addSapConnected(true))
    //               console.log("Conectado con SAP")
    //           } else {
    //               console.log('Fallo en la conexion con SAP')
    //               dispatch(addSapInfo('Desconectado'))
    //           }
    //       } catch (error) {
    //           console.log('Fallo en la conexion con SAP')
    //           console.log(error)
    //       }
    //   }
    // }

    // useEffect(() => {
    //     conectToSap()
    // },[datacred])

    useEffect(()=>{
      if (user) {
          Categoria()
          GetSku()
          perfil()
          data()
          orderTaller()
      }
    },[user])

    const [openMenu,setOpenMenu] = useState(false)

    const toggleMenu = () => {
        setOpenMenu(!openMenu)
    }

    return( <> 
    <ExpandNavBar  isOpen={openMenu} toggleMenu={toggleMenu} />
      <nav className="navbar navbar-expand-md navbar-light navigation d-flex" 
                      style={{
                      backgroundColor: '#ffffff', 
                      margin: '0',}}>
          
          {/*Link que lleva a la raiz con el nombre de la pagina*/}
    <Link className="navbar-brand mx-4 " id='LogoEmpresa' to="/micuenta"><img src={ServicesPostventaLogo} width="180" height="60" style={{marginTop:"20px"}}></img></Link>
          {/*Agregamos un boton de navegacion*/}
          {/* <button className="navbar-toggler" 
                  type="button" 
                  data-bs-toggle="collapse" 
                  data-bs-target="#navbarNav" 
                  aria-controls="navbarNav" 
                  aria-expanded="false" 
                  aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"/>
          </button> */}


          

          {/* <div className='col'> */}
            <Showafterloged /> 
          {/* </div> */}
          <div className='conteiner-menu-btn'>
          <button className='menu-btn' onClick={toggleMenu}>
                
                {openMenu ? <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                            </svg>: <svg xmlns="http://www.w3.org/2000/svg"  width="30" height="30" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
                            </svg> }
              </button>
        </div>
        </nav>
        
  </>
  )
}