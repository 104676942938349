import React, { useRef, useState, useEffect } from 'react';
import { ExportTable } from './exportTable';
import { ExportRemsTable } from './exportRemsTable';

export const ModalOrden = ({listaOrdenes}) => {
    const [tableReady, setTableReady] = useState(false);
    const tableRef = useRef();
  
    useEffect(() => {
      // Verificar si listaOrdenes tiene datos
      if (listaOrdenes && listaOrdenes.length > 0) {
        setTableReady(true);
      }
    }, [listaOrdenes]);
  
    
    const table = () => {
        return (
          <table ref={tableRef} style={{ display: 'none',maxWidth:'25rem' }}>
            <thead>
          <tr>
            <th>Fecha</th>
            <th>N° Orden</th>
            <th>Taller</th>
            <th>Cliente</th>
            <th>Cuit/dni</th>
            <th>Tipo</th>
            <th>Num OS Taller</th>
            <th>Articulo</th>
            <th>Numero de serie</th>
            <th>Estado</th>
            <th>Garantia</th>
            <th>Fecha de Compra</th>
            <th>Provincia</th>
            <th>Localidad</th>
            <th>Ultimo Usuario</th>
            <th>Fallas</th>
            <th>Fallas declaradas</th>
            <th>TimeStamp</th>
            <th>Registros</th>
          </tr>
        </thead>
        <tbody>
          {listaOrdenes && listaOrdenes.map((order, index) => (
            <tr key={index}>
              <td>{order.Fecha.toLocaleString().split(',')[0]}</td>
              <td>{order.iddoc}</td>
              <td>{order.Taller}</td>
              <td>{order.Cliente}</td>
              <td>{order.CuitDni_cli}</td>
              <td>{order.Tipo}</td>
              <td>{order.Num_Os}</td>
              <td>{order.Articulo}</td>
              <td>{order.Num_Serie}</td>
              <td>{order.Estado}</td>
              <td>{order.Garantia ? 'si' : 'no'}</td>          
              <td>{order.FechaCompra}</td>
              <td>{order.Provincia}</td>
              <td>{order.Localidad}</td>
             {/* Nuevo campo agregado */}  
             {order.Actualizado && order.Actualizado.length > 0 && (
              <>
                <td>{order.Actualizado.slice(-1)[0].usuario}</td>
              </>
            )}
              <td>{order.Desperfecto}</td>
              <td>{order.Desperfecto_declarado}</td>
              <td>{order.TimeStamp.seconds}</td>
              <td>{JSON.stringify(order.Actualizado)}</td>
          </tr>
          ))}
        </tbody>
    </table>
        );
      };

      useEffect(() => {
        if (listaOrdenes) {
          setTableReady(true);
        }
      }, [listaOrdenes]);
      
    return (
      
      <>
        {table()}
        <div className="modal fade" id="modalTableOrder" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Confirmar descarga
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p> ¿Deseas exportar la tabla a Excel?</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                  Cerrar
                </button>
                {tableReady && <ExportRemsTable tablereferencia={tableRef} />} {/* Pasa la referencia completa */}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  
  