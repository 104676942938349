import React, {useState, useRef, useEffect} from 'react'
import impresora from '../images/impresora.png'
import factura from '../images/factura.png'
import camara from '../images/camara.png'
import verificacion from '../images/lista-de-verificacion.png'
import frame from '../images/frame.png'
import { useReactToPrint } from 'react-to-print';
import CryptoJS from 'crypto-js';
import {AddOrders, GetPerfil, GetCategorias, GetRetaillist, Addworked, GetRemitoslist, GetRepuestos} from './dbfirebase'
import { useAuth } from "../context/authcontext";
import {useGeneral} from '../context/generalContext'
import {doc, collection} from "firebase/firestore";
import {db} from "../components/firebase";
import { ToastContainer, toast } from 'react-toastify';
import QRGenerator from './QRGenerator';
import {useNavigate} from "react-router-dom";
import { useSelector } from 'react-redux';

//import { AddressAutofill, AddressMinimap } from '@mapbox/search-js-react';

export const NewOrder_old = () =>{

    const navigate = useNavigate();

    const [isChecked, setIsChecked] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [isChecked3, setIsChecked3] = useState(false);
    const [isChecked4, setIsChecked4] = useState(false);

    const [image, setImage] = useState()
    const [iddoc, setIdDoc] = useState()
    const [cliente, setCliente] = useState({
        Cliente:'',
        Domicilio_cli:'',
        CuitDni_cli:'',
        Telefono_cli:'',
        Email_cli:'',
        Categoria:'-',
        Articulo:'',
        Descripcion:'-',
        Desperfecto:'',
        Comentarios:'',
        Garantia: '',
        Taller:'',
        Deposito:'37',
        Tipo:'-',
        Estado:'',
        Fecha: new Date().toLocaleString(),
        TimeStamp: new Date(),
        Mdo:'-',
        Fecha_repuestos:'-',
        Fecha_reparando:'-',
        Fecha_encentral:'-',
        Fecha_finalizado:'-',
        NumRemito:"-",
        Num_Serie:'',
        Oper_Alta_repuesto:'-',
        
    })
    
    const [facturacion, setFacturacion] = useState({
        NumFactura:'-',
        RazonSocialCli:'-',
        FechaCompra:'-',
    })

    const OrderRef = useRef(null)
    //const refrepuestos = useRef(null)

    const [selectedOption1, setSelectedOption1] = useState();
    const [selectedOption2, setSelectedOption2] = useState();
    const [selectedOption3, setSelectedOption3] = useState();
    const [selectedOption4, setSelectedOption4] = useState();
    
    const [descripcion1, setDescripcion1] = useState()
    const [descripcion2, setDescripcion2] = useState()
    const [descripcion3, setDescripcion3] = useState()
    const [descripcion4, setDescripcion4] = useState()

    const [date1, setDate1] = useState()
    const [date2, setDate2] = useState()
    const [date3, setDate3] = useState()
    const [date4, setDate4] = useState()
    const [userdatos, setUserdatos] = useState()
    const categorias = useSelector(state => state.categoriaData.categorias)
    const [repuestos, setRepuestos] = useState([])
    const [retail, setRetail] = useState([])
    const [sended, setSended] = useState(false)
    const [limpiar, setLimpiar] = useState(true)
    const [dato, setDato] = useState()
    const sku = useSelector(state => state.productData.products)
    const [repuest, setRepuest] = useState('')
    const [showQRScanner, setShowQRScanner] = useState(false);
    
    const{userData} = useGeneral()

    const perfil = async() =>{
        const dato = await userData
        setUserdatos(dato)
    }
    
    setTimeout(perfil, 1000)

    
    const handleForm1Change = (e) => {
        
        const { name, value } = e.target;
        setCliente((prevState) => ({ ...prevState, [name]: value,
                                                    CuitDni_cli:document.getElementById("cuitdni").value,
                                                    Domicilio_cli:document.getElementById("domicilio").value,
                                                    Cliente:document.getElementById("nombre").value,
                                                    Telefono_cli:document.getElementById("telefono").value,
                                                    Email_cli:document.getElementById("email").value,
                                                    Categoria:document.getElementById("categoria").value,
                                                    Tipo:document.getElementById("tipo").value,
                                                    Descripcion:document.getElementById("descripcion").value
                                                    }));
    };

    const handleForm2Change = (e) => {
        const { name, value } = e.target;
        setFacturacion((prevState) => ({ ...prevState, [name]: value,
                                                        NumRemito:document.getElementById("NumRemito").value,
                                                        }));
    };

    const handleForm3Change = (e) => {
        const { value } = e.target;
        setRepuestos((prevState) => [ ...prevState, value ]);
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
        setIsChecked3(false);
        setCliente((prevState) => ({ ...prevState, ['Garantia']: e.target.checked }))
    }

    const handleCheckboxChangenc = (e) => {
        setIsChecked3(e.target.checked);
        setIsChecked(false);
        setCliente((prevState) => ({ ...prevState, ['Garantia']: !e.target.checked }))
    }

    const handleCheckboxChange2 = (e) => {
        setIsChecked2(e.target.checked);
        setIsChecked4(false)
        setCliente((prevState) => ({ ...prevState, ['Oper_Alta_repuesto']: e.target.checked }))
    }

    const handleCheckboxChange21 = (e) => {
        setIsChecked2(false);
        setIsChecked4(e.target.checked)
        setCliente((prevState) => ({ ...prevState, ['Oper_Alta_repuesto']: !e.target.checked }))
    }
    
    const ClearForm = () => { 
        document.getElementById("Form2").reset();
        document.getElementById("categoria").value = ''
        document.getElementById("tipo").value = ''
        document.getElementById("descripcion").value = ''
    }

    const currentUrl = window.location.href
    const docRef = doc(collection(db, 'OrdenesTaller'))
    const qrData = 'https://adminservice.peabody.com.ar/nuevaorden'
    const id = docRef.id
    
    useEffect(()=>{
        let number = []
        const charArray = id.substring(5, 10).split("");
        charArray.map((char) => {
            let numb = char.charCodeAt()
            number.push(numb)
        })
        // Usamos el numero generado como clave secreta
        const secretKey = number.join('').toString()
        setIdDoc(secretKey)
    },[cliente.fantacia])

    const generateSecretKey = () => {
        const docRef = doc(collection(db, 'OrdenesTaller'));
        const id = docRef.id;

        let number = [];
        const charArray = id.substring(5, 10).split("");
        charArray.forEach((char) => {
            let numb = char.charCodeAt();
            number.push(numb);
        });

        // Usamos el numero generado como clave secreta
        const secretKey = number.join('').toString();
        setIdDoc(secretKey);
    };

    useEffect(() => {
        generateSecretKey();
    }, []);
    
    const handleFormSubmit = async() => {

        //const docRef = doc(collection(db, 'OrdenesTaller'))
        //const qrData = 'https://postventaservice.web.app/'+docRef.id
        //const id = docRef.id
        // Creamos un numero a partir del ID
        // let number = []
        // const charArray = id.substring(5, 10).split("");
        // charArray.map((char) => {
        //     let numb = char.charCodeAt()
        //     number.push(numb)
        // })
        // // Usamos el numero generado como clave secreta
        // const secretKey = number.join('').toString()
        // setIdDoc(secretKey)
        
        // const Mdo = cliente.Tipo && categorias.filter((categoria) => (
        //     categoria.Categoria === cliente.Categoria)).map((producto) => (
        //         producto[`${cliente.Tipo}`]['MDO']
        //     ))

        // const Descripcion = cliente.Tipo && categorias.filter((categoria) => (
        //     categoria.Categoria === cliente.Categoria)).map((producto) => (
        //         producto[`${cliente.Tipo}`][cliente.Articulo]
        //     ))
        
        // const Facturacion = facturacion.NumFactura && facturacion.map((f) =>{
        //     return {
        //         NumFactura: f.NumFactura || 'none',
        //         RazonSocial: f.RazonSocial || 'none',
        //         FechaCompra: f.FechaCompra || 'none',
        //       };
        // })
        
        try{
            const ord = {...cliente, ...userdatos[0], ...{iddoc:iddoc}, ...facturacion, ...{Repuestos: repuestos}} //  ...{Mdo:Mdo[0]},
            // setSended(true)
            if (Object.keys(ord).some(key => ord[key] === "" || (key === 'Categoria' || key === 'Tipo') && ord[key] === 'Buscando...')) {
                const camposVacios = Object.keys(ord).filter(key => ord[key] === "" || (key === 'Categoria' || key === 'Tipo') && ord[key] === 'Buscando...');
                setSended(false)
                toast.error(`Debe completar todos los campos de usuario. Campos vacíos: ${camposVacios.join(", ")}`,{
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true
                })     
            }else{
                await Addworked(ord.CuitDni_cli, ord.NumRemito)
                await AddOrders(ord, docRef);
                // console.log("orden",ord)
                handlePrint()
                // setSended(true)
                setCliente({ ...cliente, Articulo: '', Fecha: new Date().toLocaleString(), TimeStamp: new Date()});
                setRepuestos([])
                setLimpiar(false)
                ClearForm()
                setLimpiar(true)
                toast.success('Datos cargados con exito',{
                    position: "top-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true})
                generateSecretKey()
                // console.log("iddoc",iddoc)
            }
        }catch(error){
            console.log(error)
        }
    };  
    
    //input para subir foto de producto
    const getimage = (event) => {
        <input type="file" accept="image/*" capture id='photo' onChange={getimage}></input>
        setImage(URL.createObjectURL(event.target.files[0]));
      };
      
    //boton que dispara el input para subir la foto
    const FileUploadButton = ({getimage}) => {
        return (
            <button type="file" accept="image/*;capture=camera" style={{border:'none',backgroundColor: '#ffffff'}} onClick={getimage}>
                <label style={{margin:'0 20px'}}>Adjuntar fotos</label>
                <img src={factura} width='50rem' height='50rem' alt=''/>
            </button>
        );
    };

    // const generateID = () =>{
    //     // ID original
    //     const nombre = 'null' || document.getElementById('nombre').value
    //     const cuitdni = 'null' || document.getElementById('cuitdni').value

    //     const id = nombre+cuitdni; //Este ID puede ser generado con la concatenacion de varios datos del cliente, como nombre y dni
    //     // Creamos un numero a partir del ID
    //     let number = []
    //     const charArray = id.substring(0, 8).split("");
    //     charArray.map((char) => {
    //         let numb = char.charCodeAt()
    //         number.push(numb)
    //     })
    // }
    
    // // Usamos el numero generado como clave secreta
    // const secretKey = number.join('')
    // // Generar el hash
    // const hash = CryptoJS.AES.encrypt(id, secretKey).toString();
    
    // // Descifrar el hash para obtener el ID original
    // const bytes = CryptoJS.AES.decrypt(hash, secretKey);
    // const decryptedId = bytes.toString(CryptoJS.enc.Utf8);
    
    //Funcion imprimir
    const handlePrint = useReactToPrint({
        content: () => OrderRef.current,
        pageStyle: `
        @page {
            size: 10cm 5cm; /* A4 landscape */
            margin: 0.5cm; /* Margen de 1cm en todos los lados */
        }`,
        },
    );

    const getPageMargins = () => {
        return `@page { margin: ${'1cm'} ${'1cm'} ${'1cm'} ${'1cm'} !important; }`;
      };
    
    //datos categoria
    // const categoria = async() =>{
    //     const cat = await GetCategorias()
    //     const categ = cat.filter(objeto => Object.keys(objeto)[0] !== "Categoria")
    //     setCategorias(categ)
    // }

    const retaillist = async() =>{ 
        const retail = await GetRetaillist()
        setRetail(retail)
    }
    
    const datos = async () => {
        const remitos = await GetRemitoslist();
        if(remitos){
            const dat = remitos.flatMap((retail) => {
                if (retail.Remitos) {
                    return retail.Remitos.map((remito) => {
                    return {
                        Cuit: retail.Cuit,
                        Nombre: retail.Nombre,
                        //RazonSocial: retail.RazonSocial,
                        //Email: retail.Email,
                        //Domicilio: retail.Domicilio,
                        //Telefono: retail.Telefono,
                        Remito: remito.Remito,
                        Bultos: remito.Bultos,
                        Fecha: remito.Fecha.seconds,
                        Trabajado: remito.Trabajado
                    };
                });
            }
          });
        setDato(dat);
        }
    }

    const Repuestos = async(categoria, articulo) => {
        const Articulos = await GetRepuestos(categoria, articulo)
        setRepuest(Articulos)
    }

    useEffect(()=>{
        const cate = buscarCategoria(obj, cliente.Articulo)[1]
        if (cate){
            Repuestos(cate, cliente.Articulo)
        }
    },[cliente.Articulo])

    useEffect(()=>{
        retaillist()
        datos()
    },[])

    const buscarValor = (arr, valor) => {
        for (let i = 0; i < arr.length; i++) {
            const obj = arr[i];
            for (const key in obj) {
                if (typeof obj[key] === 'object') {
                const resultado = buscarValor([obj[key]], valor);
                    if (resultado) {
                        return resultado;
                    }
                } else if (key === valor) {
                        return obj[key];
                    }
            }
        }
        return false;
    };

    const obj = {}
    categorias.map((item) =>{
        [item].map((i)=>{
            Object.assign(obj, i);
        })
    }) 
    
    // const buscarCategoria = (objeto, claveBuscada) => {
    //     for (let nombreCategoria in objeto) {
    //         let categoria = objeto[nombreCategoria];
    //         if (typeof categoria === 'object') {
    //             if (claveBuscada in categoria) {
    //                 return nombreCategoria;
    //             } else {
    //                 let resultado = buscarCategoria(categoria, claveBuscada);
    //                 if (resultado !== false) {
    //                     return [resultado, categoria.Grupo];
    //                 }
    //             }
    //         }
    //     }
    //     return false;
    // }

    const buscarCategoria = (objeto, claveBuscada, ruta = []) => {
        for (let nombreCategoria in objeto) {
            let categoria = objeto[nombreCategoria];
            if (typeof categoria === 'object') {
                if (claveBuscada in categoria) {
                    ruta.push(nombreCategoria);
                    ruta.push(claveBuscada);
                    return ruta;
                } else {
                    let resultado = buscarCategoria(categoria, claveBuscada, ruta.concat(nombreCategoria));
                    if (resultado !== false) {
                        let grupo = buscarClave(categorias,resultado)
                        return [resultado, grupo]
                    }
                }
            }
        }
        return false;
    }
    
    function buscarClave(obj, valorBuscado) {
        for (let i=0; i<=obj.length; i++){
            for (let clave in obj[i]) {
                if (clave === valorBuscado[0]) {
                    return obj[i].Grupo;
                }
            }
        }
        return null; // valor no encontrado
    }
      


    const handleKeyPress = (e, nextInputId) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const nextInput = document.getElementById(nextInputId);
            if (nextInput && !nextInput.disabled) {
            nextInput.focus();
            }
        }
    };

    const handleAddRepuesto = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const newRepuesto = e.target.value;
            setRepuestos([...repuestos, newRepuesto]);
            e.target.value = '';
        }
    }; 
    
    const EliminarRepuesto = (r) => {
        const index = repuestos.indexOf(r);
        if (index !== -1) {
            const newRepuestos = [...repuestos];
            newRepuestos.splice(index, 1);
            setRepuestos(newRepuestos);
        }
    }

    const filtrarPorCuit = (retail, Nombre, item) => {
        const result = retail.filter(dato => dato.Nombre === Nombre);
        return result.length > 0 ? result[0][item] : null;
    }

    const filtrarPorRetail = (dato, retail) => {
        const result = dato.filter(dato => dato.Nombre === retail);
        if (result.length > 0) {     
            return result.map(obj => obj.Remito);
        } else {
          return null;
        }
      };

    
    const listafallas = [
        {
            'No Enciende': 'No enciende a nivel visual ni funcional'},
        {
            'No Acciona': 'Enciende a nivel visual pero presenta un desperfecto funcional'},
        {
            'Operativo No Visible': 'Acciona a nivel funcional pero no enciende a nivel visual'},
        {
            'Rotura': 'Presenta una rotura o desgaste en el cuerpo o accesorio'},
        {
            'Codigo De Error': 'No acciona por causa de un código de error detallado'},
        {
            'Faltante': 'Presenta un faltante de accesorio o cuerpo de producto'},
        {
            'Derretido': 'Presenta signos de derretimiento en algún insumo o accesorio'},
        {
            'No conforme': 'Es funcional pero no cumple con las expectativas'},
        {
            'Daño Inducido': 'Presenta daños por uso inadecuado o externo'},
        {
            'A Normalizar': 'Inconveniente incategorizable que requiere detalles en las observaciones'}
    ]

    const operacion = [{
        'Segunda A':'Producto OK, cambiar caja',
        'Segunda B':'Producto OK, rayado o golpeado',
        'RECHAZADO':'Producto rechazado',
        'Producto 1ra':'Producto de primera',
        'Scrap':'Destruccion total',
        'Repuestos':'Recupero de piezas',
        'Deposito 5':'Producto a deposito 5'
    }]
    
    const handleScanButtonClick = () => {
        navigate("/scanner");
      };

    
    return(
        <div className="row">
                    <div >
                        <ul className="nav nav-tabs flex-fill" role="tablist">
                            <li className="nav-item">
                                <button className="nav-link active" id="description-tab" data-bs-toggle="tab" data-bs-target="#description" type="button" role="tab" aria-controls="description" aria-selected="true">Generar Ordenes de trabajo (VERSION VIEJA) </button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link" id="history-tab" data-bs-toggle="tab" data-bs-target="#history" type="button" role="tab" aria-controls="history" aria-selected="false">Escanear y modificar una orden</button>
                            </li>
                        </ul>
                    </div>
                    
                    <div className="tab-content mt-3">        
                        <div className="tab-pane active" id="description" role="tabpanel" aria-labelledby="description-tab" style={{marginBottom:'20px' ,marginTop:'20px'}}>
                            <style>{getPageMargins()}</style>
                            <ToastContainer/>
                            <div className='container' >
                        
                                <p className='d-flex justify-content-end'>Fecha: {new Date().toLocaleString().split(',')[0]}</p>
                            {/* Titulo y ID documento */}
                                <div className="row" style={{marginBottom:'15px'}}>
                                    
                                    <div className='col-6 d-flex justify-content-start'>
                                        <h2>Orden de trabajo</h2>
                                    </div>
                                    <div className='col-6 d-flex justify-content-end'>
                                        <p>ID: {iddoc? iddoc:'Documento sin registrar'}</p>
                                    </div>
                                </div>
                            {/* Datos taller y boton imprimir */}
                                <div style={{borderBottom: '1px solid', width:'100%',marginBottom:'20px', borderBottomColor:'#bcbcbc'}}>
                                    <div className="row">
                                        {userdatos && <p className='col-lg-6 d-flex mb-1'>Responsable: {userdatos[0].Titular}</p>}
                                        {userdatos && <p className='col-lg-6 d-flex mb-1'>Razon social: {userdatos[0].Taller}</p>}
                                        {userdatos && <p className='col-lg-6 d-flex mb-1'>Domicilio: {userdatos[0].Direccion}</p>}
                                        {userdatos && <p className='col-lg-6 d-flex mb-1'>Telefono: {userdatos[0].Telefono}</p>}
                                        {userdatos && <p className='col-lg-6 d-flex mb-1'>E-mail: {userdatos[0].Email}</p>}
                                        {userdatos && <p className='col-lg-6 d-flex mb-1'>Cuit: {userdatos[0].Cuit}</p>}
                                    </div>
                                    <div className="row" style={{marginBottom:'20px'}}>
                                        <div className='col-12 d-flex justify-content-end d-print-none'>
                                            <button style={{border:'none',backgroundColor: '#ffffff'}} onClick={()=>{handlePrint()}}>
                                                <label style={{margin:'0 20px'}}>Imprimir</label>
                                                <img src={impresora} width='50rem' height='50rem' alt=''/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-12 d-flex justify-content-start' style={{marginBottom:'20px'}}>
                                        <h4>Datos del cliente:</h4>
                                    </div>
                                
                                    <form style={{marginBottom:'20px'}} id="Form1">
                                        <div className="row" style={{borderBottom: '1px solid', width:'100%',borderBottomColor:'#bcbcbc'}}> 

                                                <div className='col-lg-6 d-flex mb-3 contenedor' style={{alignItems: 'center' }}>
                                                    <label style={{margin:'0 1rem', maxWidth:'5rem', minWidth:'5rem'}}>Nombre:</label>
                                                    <input 
                                                        type = "text"
                                                        name = "fantacia"
                                                        className = "form-control"
                                                        placeholder = "Nombre de Fantasia"
                                                        id = "fantasia"
                                                        list='Fantasia'
                                                        autoComplete = "off"
                                                        onChange={handleForm1Change}
                                                        style={{maxWidth:'25rem'}}
                                                        disabled={limpiar? false:true}//
                                                        //value={filtrarPorCuit(retail, cliente.CuitDni_cli, "Nombre") || ''}
                                                        />
                                                        {/* <datalist id="Fantasia">
                                                            {retail.map((retail) => {
                                                                return(
                                                                <option key={retail.Nombre} value={retail.Nombre}>
                                                                {retail.Nombre}    
                                                                </option>)})
                                                            }
                                                        </datalist> */}
                                                        {/* <datalist id="Fantasia">
                                                        {dato && dato.map((item, index) => (
                                                            <option key={index} value={item.Nombre}>
                                                                {item.Nombre}
                                                            </option>
                                                            ))}
                                                        </datalist> */}
                                                        <datalist id="Fantasia">
                                                        {dato &&
                                                            dato
                                                            // Filtrar para obtener valores únicos basados en el campo 'Nombre'
                                                            .filter((item, index, self) => self.findIndex((t) => t.Nombre === item.Nombre) === index)
                                                            .map((item, index) => (
                                                                <option key={index} value={item.Nombre}>
                                                                {item.Nombre}
                                                                </option>
                                                            ))}
                                                        </datalist>

                                                </div>

                                                <div className='col-lg-6 d-flex ms-auto mb-3 contenedor ' style={{alignItems: 'center' }}>
                                                    <label style={{margin:'0 1rem', maxWidth:'5rem',minWidth:'5rem'}}>Cuit/Dni:</label>
                                                    <input 
                                                        type = "number"
                                                        name = "CuitDni_cli"
                                                        //onChange = {handlechange} 
                                                        className = "form-control"
                                                        placeholder = "Sin guiones ni puntos"
                                                        id = "cuitdni"
                                                        // autoFocus
                                                        onChange={handleForm1Change}
                                                        style={{maxWidth:'25rem'}}
                                                        disabled={true}
                                                        //onKeyPress={(e) => handleKeyPress(e, 'articulo')}
                                                        value={filtrarPorCuit(retail, cliente.fantacia, "Cuit") || ''}
                                                        />
                                                </div>
                                                
                                                <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{alignItems: 'center' }}>
                                                    <label style={{margin:'0 1rem', maxWidth:'5rem',minWidth:'5rem'}}>Domicilio:</label>
                                                    {/* <AddressAutofill accessToken=""
                                                                    options={{
                                                                        language: 'es',
                                                                        country: 'AR',
                                                                    }}> */}
                                                        <input 
                                                            type = "text"
                                                            name = "Domicilio_cli"
                                                            //onChange = {handlechange} 
                                                            className = "form-control"
                                                            placeholder = "Calle, localidad, provincia"
                                                            id = "domicilio"
                                                            onChange={handleForm1Change}
                                                            style={{maxWidth:'25rem'}}
                                                            disabled={true}
                                                            autoComplete="shipping address-line1"
                                                            value={filtrarPorCuit(retail, cliente.fantacia, "Domicilio") || ''}
                                                            />
                                                    {/* </AddressAutofill> */}
                                                    {/* <div>
                                                    <AddressMinimap show={true} query={cliente.Domicilio_cli}>
                                                    </AddressMinimap>
                                                    </div> */}
                                                </div>

                                                <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{alignItems: 'center' }}>
                                                    <label style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Razon Social:</label>
                                                    <input 
                                                        type = "text"
                                                        name = "Cliente"
                                                        //onChange = {handlechange} 
                                                        className = "form-control"
                                                        placeholder = "Razon social"
                                                        id = "nombre"
                                                        onChange={handleForm1Change}
                                                        style={{maxWidth:'25rem'}}
                                                        disabled={true}
                                                        value={filtrarPorCuit(retail, cliente.fantacia, "RazonSocial") || ''}
                                                        />
                                                </div>
                                                
                                                <div className='col-lg-6 d-flex ms-auto mb-3 contenedor' style={{alignItems: 'center' }}>
                                                    <label style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Telefono:</label>
                                                    <input 
                                                        type = "text"
                                                        name = "Telefono_cli"
                                                        //onChange = {handlechange} 
                                                        className = "form-control"
                                                        placeholder = "Sin guiones ni puntos"
                                                        id = "telefono"
                                                        onChange={handleForm1Change}
                                                        style={{maxWidth:'25rem'}}
                                                        disabled={true}
                                                        value={filtrarPorCuit(retail, cliente.fantacia, "Telefono") || ''}
                                                        />
                                                </div>
                                                            
                                                <div className='col-lg-6 d-flex mb-3 contenedor' style={{alignItems: 'center' }}>
                                                    <label style={{margin:'0 1rem', maxWidth:'5rem', minWidth:'5rem'}}>E-mail:</label>
                                                    <input 
                                                        type = "email"
                                                        name = "Email_cli"
                                                        //onChange = {handlechange} 
                                                        className = "form-control"
                                                        placeholder = "ejemplo@ejemplo.com.ar"
                                                        id = "email"
                                                        onChange={handleForm1Change}
                                                        style={{maxWidth:'25rem'}}
                                                        disabled={true}
                                                        value={filtrarPorCuit(retail, cliente.fantacia, "Email") || ''}
                                                        />
                                                </div>

                                                

                                        </div>           
                                    </form>
                                </div>
                                <div className="row ">
                                    <div className='col-6 d-flex justify-content-start'>
                                        <h4>Datos del producto:</h4>
                                    </div>
                                    <div className='col-6 d-flex justify-content-center'>
                                    {isChecked3? <h5>Nota de credito</h5>: isChecked?<h5>Reparar y devolver</h5>:""}
                                    
                                </div>
                                
                                {/* <div className='col-6 d-flex ms-1 mb-4 form-check form-switch ' style={{alignItems: 'center' }}>
                                    <label style={{margin:'0 1rem'}}>Procesar como RyD: </label>
                                        <input className="form-check-input" 
                                                type="checkbox"
                                                role="switch"
                                                value=""
                                                name="Garantia"
                                                id="flexCheckDefault" 
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                                disabled={limpiar? false:true}
                                                style={{margin:'initial'}}/>
                                </div> */}
                                <div className='col-6 d-flex ms-1 mb-4' style={{ alignItems: 'center' }}>
                                    {/* <label style={{ margin: '0 1rem' }}>Procesar como RyD: </label> */}
                                    <div className="form-check form-check-inline">
                                        
                                            <input
                                                className="form-check-input mx-4"
                                                type="checkbox"
                                                value=""
                                                name="Garantia"
                                                id="flexCheckReparar"
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                                disabled={limpiar ? false : true}
                                                style={{ margin: 'initial' }}
                                            />
                                        
                                            <label className="form-check-label" htmlFor="flexCheckReparar">R&D</label>
                                        
                                    </div>
                                    <div className="form-check form-check-inline">
                                        
                                            <input
                                                className="form-check-input mx-4"
                                                type="checkbox"
                                                value=""
                                                name="Garantia"
                                                id="flexCheckDevolver"
                                                checked={isChecked3}
                                                onChange={handleCheckboxChangenc}
                                                disabled={limpiar ? false : true}
                                                style={{ margin: 'initial' }}
                                            />
                                        
                                        
                                            <label className="form-check-label" htmlFor="flexCheckDevolver">N/C</label>
                                        
                                    </div>
                                </div>

                                    
                  {isChecked && <div className="row"> 
                                            {/* <div className='col-lg-3 d-flex justify-content-start ms-auto mb-3 flex-column align-items-center'>
                                                <label style={{margin:'0 1rem',maxWidth:'10rem',minWidth:'1rem'}}>N° Remito:</label>
                                                <input type = "text"
                                                        name = "NumFactura"
                                                        className = "form-control"
                                                        placeholder = "Nº Factura"
                                                        id = "NumFactura"
                                                        onChange={handleForm2Change}
                                                        style={{minWidth:'8rem'}}
                                                        disabled={iddoc? true:false}
                                                        />
                                            </div> */}
                                            <div className='col-lg-6 d-flex justify-content-start ms-auto mb-3 flex-column align-items-center'>
                                                <label style={{margin:'0 1rem',maxWidth:'10rem',minWidth:'1rem'}}>Razon Social:</label>
                                                <input type = "text"
                                                        name = "RazonSocialCli"
                                                        className = "form-control"
                                                        placeholder = "Razon Social"
                                                        id = "RazonSocialCli"
                                                        onChange={handleForm2Change}
                                                        style={{minWidth:'8rem'}}
                                                        disabled={true}
                                                        value = {isChecked? filtrarPorCuit(retail, cliente.fantacia, "RazonSocial") || '':"-"}
                                                        />
                                            </div>
                                            <div className='col-lg-6 d-flex justify-content-start ms-auto mb-3 flex-column align-items-center'>
                                                <label style={{margin:'0 1rem',maxWidth:'10rem',minWidth:'1rem'}}>Fecha:</label>
                                                <input type = "date"
                                                        name = "FechaCompra"
                                                        className = "form-control"
                                                        placeholder = "Fecha de compra"
                                                        id = "FechaCompra"
                                                        onChange={handleForm2Change}
                                                        style={{minWidth:'8rem'}}
                                                        disabled={limpiar? false:true}
                                                        />
                                            </div>
                                            {/* <div className='col-3 d-flex justify-content-end image-upload d-print-none' style={{marginBottom:'20px'}}>
                                                    <label htmlFor='photo'>Adjuntar factura de compra
                                                        <img src={factura} width='55rem' height='55rem' alt=''/>
                                                    </label>
                                                    <input type="file" accept="image/*" capture id='photo' onChange={getimage}></input>
                                            </div> */}
                                </div>}
                                    
                                <form style={{marginBottom:'20px'}} id="Form2">
                                    <div className="row" style={{borderBottom: '1px solid', width:'100%',borderBottomColor:'#bcbcbc'}}>

                                            <div className='col-lg-2 d-flex ms-auto mb-3 flex-column align-items-center'>
                                                <label style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'1rem'}}>N°Remito:</label>
                                                <input type = "text"
                                                        name = "NumRemito"
                                                        className = "form-control"
                                                        placeholder = "Nº Remito"
                                                        id = "NumRemito"
                                                        list = "numRemito"
                                                        autoComplete = "off"
                                                        onChange={handleForm2Change}
                                                        style={{minWidth:'8rem'}}
                                                        disabled={limpiar? false:true}
                                                        //value = {cliente.fantacia && filtrarPorRetail(dato, cliente.fantacia, "Remitos")}
                                                        />
                                                        {cliente.fantacia && filtrarPorRetail(dato, cliente.fantacia) !== null && 
                                                        <datalist id="numRemito">
                                                            {filtrarPorRetail(dato, cliente.fantacia).map(remito => (
                                                                <option key={remito} value={remito}>
                                                                {remito}
                                                                </option>
                                                            ))}
                                                        </datalist>}
                                            </div>

                                            <div className='col-lg-2 d-flex ms-auto mb-3 flex-column align-items-center'>
                                                <label style={{margin:'0 1rem', maxWidth:'5rem',minWidth:'1rem'}}>Articulo:</label>
                                                <input type = "text"
                                                        name = "Articulo"
                                                        //onChange = {handlechange} 
                                                        className = "form-control"
                                                        placeholder = "Articulo"
                                                        id = "articulo"
                                                        list="Articulo"
                                                        autoComplete="off"
                                                        // funcion que hace que todo lo que tipeen se pase a mayus
                                                        onChange={(e) => { e.target.value = e.target.value.toUpperCase(); handleForm1Change(e); }}
                                                        style={{minWidth:'8rem'}}
                                                        onKeyPress={(e) => handleKeyPress(e, "falla")}
                                                        
                                                        />
                                                        {sku && 
                                                        <datalist id="Articulo">
                                                            {sku.map(art => (
                                                                <option key={art} value={art}>
                                                                {art}
                                                                </option>
                                                            ))}
                                                        </datalist>}
                                            </div>
                                        
                                            <div className='col-lg-2 d-flex ms-auto mb-3 flex-column align-items-center'>
                                                <label style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'1rem'}}>Categoria:</label>
                                                <input type = "text"
                                                        name = "Categoria"
                                                        className = "form-control"
                                                        placeholder = "Categorias"
                                                        id = "categoria"
                                                        list="Categoria"
                                                        autoComplete="off"
                                                        onChange={handleForm1Change}
                                                        style={{minWidth:'8rem'}}
                                                        disabled={limpiar? true:false}
                                                        value={cliente.Articulo && buscarCategoria(obj, cliente.Articulo)[1]? buscarCategoria(obj, cliente.Articulo)[1]:'Buscando...' || ''}
                                                        />
                                            </div>
                                            <div className='col-lg-2 d-flex ms-auto mb-3 flex-column align-items-center'>
                                                <label style={{margin:'0 1rem', maxWidth:'5rem',minWidth:'1rem'}}>Tipo:</label>
                                                <input type = "text"
                                                        name = "Tipo"
                                                        className = "form-control"
                                                        placeholder = "Tipo"
                                                        id = "tipo"
                                                        list="Tipo"
                                                        autoComplete="off"
                                                        onChange={handleForm1Change}
                                                        style={{minWidth:'8rem'}}
                                                        disabled={true}
                                                        value={cliente.Articulo && buscarCategoria(obj, cliente.Articulo)[0]? buscarCategoria(obj, cliente.Articulo)[0][0]:'Buscando...' || ''}
                                                        />
                                            </div>
                                            <div className='col-lg-2 d-flex ms-auto mb-3 flex-column align-items-center'>
                                                <label style={{margin:'0 1rem', maxWidth:'15rem',minWidth:'1rem'}}>Descripcion:</label>
                                                <input 
                                                    type = "text"
                                                    name = "Descripcion"
                                                    //onChange = {handlechange} 
                                                    className = "form-control"
                                                    placeholder = "Descripcion"
                                                    id = "descripcion"
                                                        
                                                    onChange={handleForm1Change}
                                                    style={{minWidth:'8rem'}}
                                                    disabled={true}
                                                    value={buscarValor(categorias, cliente.Articulo)? buscarValor(categorias, cliente.Articulo):'Buscando...' || ''}
                                                    />
                                            </div>
                                            <div className='col-lg-12 d-flex ms-auto mb-3 contenedor' style={{ alignItems: 'center' }}>
                                                <label style={{ margin: '0 1rem', maxWidth: '5rem', minWidth: '5rem' }}>Numero de serie:</label>
                                                <input
                                                    type="text"
                                                    name="Num_Serie"
                                                    //onChange = {handlechange} 
                                                    className="form-control"
                                                    placeholder="Numero de serie"
                                                    id="Num_serie"
                                                    disabled={!cliente.Articulo}
                                                    onChange={handleForm1Change}
                                                // disabled={sended? true:false}
                                                />
                                            </div>
                                            {/* <div className='col-lg-12 d-flex ms-auto mb-3 contenedor' style={{alignItems: 'center' }}>
                                                <label style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Falla:</label>
                                                <input 
                                                    type = "text"
                                                    name = "Desperfecto"
                                                    //onChange = {handlechange} 
                                                    className = "form-control"
                                                    placeholder = "Descripcion de la falla del producto"
                                                    id = "falla"
                                                    list='Falla'
                                                    //autoComplete="off"
                                                    disabled={!cliente.Articulo}
                                                    onChange={handleForm1Change}
                                                    disabled={limpiar? false:true}
                                                    />
                                                    <datalist id="Falla">
                                                        {listafallas.map((fallas) => (
                                                            Object.keys(fallas).map((key) =>
                                                            <option key={key} value={key}>
                                                            {fallas[key]}    
                                                            </option>)
                                                        ))}
                                                        </datalist>
                                            </div> */}
                                            <div className='col-lg-12 d-flex ms-auto mb-3 contenedor' style={{alignItems: 'center' }}>
                                                <label style={{margin:'0 1rem',maxWidth:'5rem',minWidth:'5rem'}}>Falla:</label>
                                                <select
                                                    name="Desperfecto"
                                                    className="form-control"
                                                    placeholder="Descripcion de la falla del producto"
                                                    id="falla"
                                                    onChange={handleForm1Change}
                                                    disabled={!cliente.Articulo}
                                                    disabled={limpiar ? false : true}
                                                >
                                                    <option value="">Seleccionar...</option>
                                                    {listafallas.map((fallas) => (
                                                        Object.keys(fallas).map((key) => (
                                                            <option key={key} value={key}>
                                                                {fallas[key]}
                                                            </option>
                                                        ))
                                                    ))}
                                                </select>
                                            </div>        
                                            <div className='col-lg-12 d-flex mb-3 contenedor' style={{alignItems: 'center' }}>
                                                <label style={{margin:'0 1rem', maxWidth:'5rem', minWidth:'5rem'}}>Observacion:</label>
                                                <input 
                                                    type = "text"
                                                    name = "Comentarios"
                                                    //onChange = {handlechange} 
                                                    className = "form-control"
                                                    placeholder = "Estado general del producto. ej. Marcas, Sin accesorios, en caja original...etc."
                                                    id = "observaciones"
                                                    autoComplete="off"
                                                    disabled={!cliente.Articulo}
                                                    onChange={handleForm1Change}
                                                    disabled={limpiar? false:true}
                                                    />
                                            </div>

                                            <div className='col-lg-3 d-flex mb-3 d-print-none contenedor' style={{alignItems: 'center' }}>
                                                <label style={{margin:'0 1rem', maxWidth:'5rem', minWidth:'5rem'}}>{cliente.Garantia?'Repuestos utilizados:': 'Repuestos'}</label>
                                                <input 
                                                    type = "text"
                                                    name = "Repuestos"
                                                    //onChange = {handlechange} 
                                                    className = "form-control"
                                                    placeholder = {cliente.Garantia?'Repuestos utilizados:':'Repuestos recuperados'}
                                                    id = "repuestos"
                                                    list = "rep"
                                                    autoComplete="off"
                                                    disabled={!cliente.Articulo}
                                                    //onChange={handleForm3Change}
                                                    disabled={limpiar? false:true}
                                                    onKeyPress={handleAddRepuesto}
                                                    />
                                                {/* <datalist id="rep">
                                                        {repuest && repuest[0].repuestos.map((rep) => {
                                                            console.log(repuest)
                                                            return(
                                                            <option key={Object.keys(rep)} value={Object.keys(rep)}>
                                                            {[Object.keys(rep)]}    
                                                            </option>)
                                                        })}
                                                        </datalist>      */}
                                                    <datalist id="rep">
                                                        {repuest && repuest[0].repuestos.map((rep) => {
                                                            const repuestoKey = Object.keys(rep)[0];
                                                            const descripcion = rep[repuestoKey].Descripción;

                                                            return (
                                                            <option key={repuestoKey} value={repuestoKey}>
                                                                {repuestoKey} - {descripcion}
                                                            </option>
                                                            );
                                                        })}
                                                    </datalist>

                                            </div>
                            {isChecked3 &&   <div className='col-lg-3 d-flex ms-1' style={{alignItems: 'center' }}>
                                                {/* <label style={{margin:'0 1rem', maxWidth:'10rem', minWidth:'5rem'}}>{cliente['Oper_Alta_repuesto']?'Desarme de producto':'Recupero de producto'}</label> */}
                                                {/* <input className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    value=""
                                                    name="Oper_Alta_repuesto"
                                                    id="flexCheckDefault2" 
                                                    checked={isChecked2}
                                                    onChange={handleCheckboxChange2}
                                                    disabled={!cliente.Garantia? false:true}
                                                    style={{margin:'initial'}}/> */}
                                            {/* </div>
                                            <div className='col-6 d-flex ms-1 mb-4' style={{ alignItems: 'center' }}> */}
                                                {/* <label style={{ margin: '0 1rem' }}>Procesar como RyD: </label> */}
                                                <div className="form-check form-check-inline p-0">
                                                        <input
                                                            className="form-check-input mx-4"
                                                            type="checkbox"
                                                            value=""
                                                            name="Oper_Alta_repuesto"
                                                            id="flexCheckReparar"
                                                            checked={isChecked2}
                                                            onChange={handleCheckboxChange2}
                                                            disabled={limpiar ? false : true}
                                                            style={{ margin: 'initial' }}
                                                        />
                                                        <label className="form-check-label" htmlFor="flexCheckReparar" style={{margin:'0 1rem', maxWidth:'10rem', minWidth:'8rem'}}>Desarme de producto</label>
                                                </div>
                                                <div className="form-check form-check-inline p-0">
                                                        <input
                                                            className="form-check-input mx-4"
                                                            type="checkbox"
                                                            value=""
                                                            name="Oper_Alta_repuesto"
                                                            id="flexCheckDevolver"
                                                            checked={isChecked4}
                                                            onChange={handleCheckboxChange21}
                                                            disabled={limpiar ? false : true}
                                                            style={{ margin: 'initial' }}
                                                        />
                                                        <label className="form-check-label" htmlFor="flexCheckDevolver" style={{margin:'0 1rem', maxWidth:'10rem', minWidth:'8rem'}}>Recupero de producto</label>
                                                </div>
                                            </div>}
                                            <div className='col-lg-3 d-flex mb-3 contenedor' style={{alignItems: 'center' }}>
                                                <label style={{margin:'0 1rem', maxWidth:'5rem', minWidth:'5rem'}}>Estado:</label>
                                                <input 
                                                    type = "text"
                                                    name = "Estado"
                                                    //onChange = {handlechange} 
                                                    className = "form-control"
                                                    placeholder = "Operacion de cierre"
                                                    id = "Estado"
                                                    list='estado'
                                                    autoComplete="off"
                                                    disabled={!cliente.Articulo}
                                                    onChange={handleForm1Change}
                                                    disabled={limpiar? false:true}
                                                    // style={{maxWidth:'8rem', minWidth:'8rem'}}
                                                    />
                                                    <datalist id="estado">
                                                        {operacion.map((op) => (
                                                            Object.keys(op).map((key) =>
                                                            <option key={key} value={key}>
                                                            {op[key]}    
                                                            </option>)
                                                        ))}
                                                        </datalist>
                                            </div>
                                            <div className='col-lg-3 d-flex mb-3' style={{alignItems: 'center' }}>
                                                <label style={{margin:'0 1rem', backgroundColor: 'lightblue', minWidth:'5rem', fontSize: '1.5rem'}}>
                                                    {!isChecked && cliente.Estado == 'Segunda B'? 'Transferir a deposito 42':''}
                                                    </label>
                                            </div>
                            {repuestos && <ol style={{ display: 'flex', flexWrap: 'wrap', listStyle: 'none', margin: 0, padding: 0 }}>
                                                {repuestos && Object.values(repuestos).map((r, i) => {
                                                    return(
                                                        <li key={`${r + i}`} style={{ padding: '0.5rem', marginRight: '0.5rem' }}>
                                                            
                                                            <div style={{ display: 'flex', alignItems: 'center',  padding: '1rem', position: 'relative' }}>
                                                                <span style={{ marginRight: '0.5rem', fontSize:'small'}}>{r}</span>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => {EliminarRepuesto(r);}}
                                                                        style={{
                                                                        backgroundColor: 'red',
                                                                        color: '#fff',
                                                                        borderRadius: '50%',
                                                                        border: 'none',
                                                                        width: '1rem',
                                                                        height: '1rem',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        right: 0,
                                                                        cursor: 'pointer'
                                                                        }}
                                                                    >
                                                                    x
                                                                    </button>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ol>}
                                    </div>           
                                </form>
                                        <div className="row">
                                            <div className='col-12 d-flex justify-content-start d-print-none'>
                                                {/* <h4>Datos del servicio:</h4> */}
                                            </div> 
                                        {/* <div className='col-12 d-flex justify-content-end image-upload d-print-none'style={{marginBottom:'20px'}}>
                                                <label htmlFor='photo'>Adjuntar fotos:    
                                                    <img src={camara} width='50rem' height='50rem' alt='' style={{margin:'0 0 0 20px'}}/>
                                                </label>
                                                <input type="file" accept="image/*" capture id='photo' onChange={getimage} capture='environment'></input>
                                        </div> */}
                                            <div className='card-body col-12 d-flex justify-content-start d-print-none' style={{ overflowX: "auto",marginBottom:'20px' }}>
                                            
                                            </div>
                                        {!sended &&
                                            <div className='col-12 d-flex justify-content-center d-print-none' style={{marginBottom:'20px'}}>
                                                <button type="submit" onClick={handleFormSubmit} className='col-12 btn btn-outline-success' disabled={limpiar? false:true} >Guardar</button>  
                                            </div>}
                                        {/* <div className='col-12 d-flex justify-content-end image-upload d-print-none'style={{marginBottom:'20px'}}>
                                                <label htmlFor='photo'>Adjuntar conformidad:    
                                                    <img src={verificacion} width='50rem' height='50rem' alt='' style={{margin:'0 0 0 20px'}}/>
                                                </label>
                                                <input type="file" accept="image/*" capture id='photo' onChange={getimage}></input>
                                        </div> */}
                                            <div className='row' ref={OrderRef}>
                                                <div className='col-md-4 ' style={{marginTop:'130px'}}>
                                                    <label className='d-flex justify-content-center'>......................................................................</label>
                                                    <label className='d-flex justify-content-center'>Firma</label>
                                                </div>
                                                {/* QR para acceder a la app del cliente */}
                                                <div className='col-md-4' style={{marginTop:'20px'}}>
                                                        <p className='d-flex justify-content-start mx-3' style={{margin:'0 0 0 3rem'}}>Producto: {cliente.Articulo}</p>
                                                        <p className='d-flex justify-content-start mx-3' style={{margin:'0 0 0 3rem'}}>Descripcion: {cliente.Descripcion}</p>
                                                        <p className='d-flex justify-content-start mx-3' style={{margin:'0 0 0 3rem'}}>Estado: {cliente.Estado}</p>
                                                        <p className='d-flex justify-content-start mx-3' style={{margin:'0 0 0 3rem'}}>Falla: {cliente.Desperfecto}</p>
                                                        {userdatos && <p className='d-flex justify-content-start mx-3' style={{margin:'0 0 0 3rem'}}>Usuario: {userdatos[0].Titular}</p>}
                                                        <p className='d-flex justify-content-start mx-3'style={{margin:'0 0 0 3rem'}}>Orden: {iddoc? iddoc:'Documento sin registrar'}</p>    
                                                </div>
                                                <div className='col-md-4 d-flex justify-content-center'style={{marginTop:'20px'}} >
                                                        <QRGenerator data={qrData}/>
                                                </div>
                                            </div>

                                        </div>
                                </div>
                                </div>  
                            </div>

                            {/* Tab dos */}
                            <div className="tab-pane" id="history" role="tabpanel" aria-labelledby="history-tab">
                                <div className="row d-print-none" style={{marginBottom:'15px'}}>
                                    <button className='col-12 btn btn-outline-success' style={{textAlign: 'center'}} onClick={handleScanButtonClick} >Escanear y editar orden</button>
                                </div>
                                {/* {showQRScanner && <QRScanner setShowQRScanner={setShowQRScanner}/>}    */}
                            </div> 
                 </div> 
        </div>      
    )  
                            
}