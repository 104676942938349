import React, { useRef, useState, useEffect } from 'react';
import { ExportTable } from './exportTable';
import { ExportRemsTable } from './exportRemsTable';

export const ModalDescargaExc = ({listaOrdenes}) => {
    //console.log(listaOrdenes)
   const [tableReady, setTableReady] = useState(false);
   const tableRef = useRef();
 
   useEffect(() => {
     // Verificar si listaOrdenes tiene datos
     if (listaOrdenes && listaOrdenes.length > 0) {
       setTableReady(true);
     }
   }, [listaOrdenes]);
 
   const fechaCalculator = (fecha) => {
    const today = Date.now()
    const daysDifference = Math.floor(
        Math.floor(today / 1000 - fecha) / (60 * 60 * 24)
    );
    
  //  console.log(daysDifference)
    return daysDifference;
  
};


     const table = () => {
       return (
         <table ref={tableRef} style={{ display: 'none' }}>
           <thead>
             <tr> 
               <th>Nombre</th>
               <th>Fecha</th>
               <th>Remito</th>
               {/* <th>Remito Salida</th>
               <th>RyD</th>
               <th>N/C</th>
               <th>Ult Fecha</th> */}
               <th>Productos</th>
               <th>Cantidad</th>
               {/* <th>Trabajado</th>
               <th>Pendiente</th> */}
               <th>Creado por</th>
               {/* <th>TimeStamp </th> */}
               {/* <th>TimeStamp</th> */}
                 
             </tr>
           </thead>
           <tbody>
                {listaOrdenes && listaOrdenes.map((order, index) => (
                    <tr key={index}>
                    <td>{order.Nombre}</td>
                    <td>{(new Date(order.Fecha).toLocaleString().split(',')[0])}</td>
                    <td>{order.Remito}</td>
                    {/* <td>{order.remitoSalida}</td> */}
                    {/* <td>{order.Garantia.conGarantia}</td>
                    <td>{order.Garantia.sinGarantia}</td>   */}
                    {/* <td>{order.Ult_Fecha}</td>  */}
                    <td>{order.Producto}</td>
                    <td>{order.Cantidad}</td>
                    {/* <td>{order.Trabajado}</td> */}
                    {/* <td>{order.Bultos - order.Trabajado}</td>  */}
                    <td>{order.Creator}</td>
                    {/* <td>{fechaCalculator(order.Fecha)}</td> */}
                    </tr>
                ))}
            </tbody>
         </table>
       );
     };
 
     useEffect(() => {
       if (listaOrdenes) {
         setTableReady(true);
       }
     }, [listaOrdenes]);
     
   return (
     
     <>
       {table()}
       <div className="modal fade" id="modalTableDowload" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div className="modal-dialog">
           <div className="modal-content">
             <div className="modal-header">
               <h5 className="modal-title" id="exampleModalLabel">
                 Confirmar descarga
               </h5>
               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
             </div>
             <div className="modal-body">
               <p> ¿Deseas exportar la tabla a Excel?</p>
             </div>
             <div className="modal-footer">
               <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                 Cerrar
               </button>
               {tableReady && <ExportRemsTable tablereferencia={tableRef} />} Pasa la referencia completa
             </div>
           </div>
         </div>
       </div>
     </>
   )
 }
 