import React, {useMemo, useEffect, useState, useRef} from 'react';
import {AddRepuestoState, EditOrdenTallerState} from './dbfirebase'
import {ModalPrecio} from './ModalNuevoPrecio'


export const columnsOrdenes = [
    {
        Header: "Fecha",
        accessor: "TimeStamp",
        Cell: ({value}) => {return(new Date(value.seconds * 1000).toLocaleString().split(',')[0])}
    },
    {
        Header: "N° Orden",
        accessor: "iddoc"
    },
    {
        Header: "Taller",
        accessor: "Taller"
    },
    // {
    //     Header: "Titular",
    //     accessor: "Titular"
    // },
    {
        Header: "Cliente",
        accessor: "Cliente"
    },
    {
        Header: "Cuit/Dni",
        accessor: "CuitDni_cli"
    },
    // {
    //     Header: "Email",
    //     accessor: "Email_cli"
    // },
    // {
    //     Header: "Categoria",
    //     accessor: "Categoria"
    // },
    {
        Header: "Tipo",
        accessor: "Tipo"
    },
    {
        Header: "Num. Os taller",
        accessor: "Num_Os"
    },
    {
        Header: "Articulo",
        accessor: "Articulo"
    },
    {
        Header: "Estado",
        accessor: "Estado",
        Cell: ({ cell }) => {
            const estado = cell.value;
            const cellStyle = {
              backgroundColor: estado === 'Finalizado'? '#C4EEB1': estado === 'Reparado'? '#ffd966': estado === 'PresAceptado'? '#7FB3D5': estado === 'Diagnosticado'?'#EC7063':'white',
            };
        
            return (
              <div style={cellStyle}>
                {cell.value}
              </div>
            );
          },
    },
    // {
    //     Header: "Costo MDO",
    //     accessor: "Mdo",
    // },
    {
        Header: "Garantia",
        accessor: "Garantia",
        Cell: ({value}) => {if(value){return('SI')}else{return('NO')}}
    },
    // {
    //     Header: "N° Factura",
    //     accessor: "NumFactura"
    // },
    {
        Header: "Fecha de Compra",
        accessor: "FechaCompra"
    },
    // {
    //     Header: "Razon social",
    //     accessor: "RazonSocialCli"
    // },
    // {
    //     Header: "Repuestos",
    //     accessor: (row) => {
    //         if(row.Actualizado){
    //             const idx = row.Actualizado.findIndex(
    //             (act) => act.Fecha_repuestos !== undefined
    //             );
    //             return idx >= 0 ? row.Actualizado[idx].Fecha_repuestos.seconds : null;
    //         }},
    //       Cell: ({ value }) =>
    //         value ? new Date(value * 1000).toLocaleDateString() : null,
    // },
    // {
    //     Header: "Reparando",
    //     accessor: (row) => {
    //         if(row.Actualizado){
    //             const idx = row.Actualizado.findIndex(
    //             (act) => act.Fecha_reparando !== undefined
    //             );
    //             return idx >= 0 ? row.Actualizado[idx].Fecha_reparando.seconds : null;
    //         }},
    //       Cell: ({ value }) =>
    //         value ? new Date(value * 1000).toLocaleDateString() : null,
    // },
    // {
    //     Header: "Central",
    //     accessor: (row) => {
    //         if(row.Actualizado){
    //             const idx = row.Actualizado.findIndex(
    //             (act) => act.Fecha_encentral !== undefined
    //             );
    //             return idx >= 0 ? row.Actualizado[idx].Fecha_encentral.seconds : null;
    //         }},
    //       Cell: ({ value }) =>
    //         value ? new Date(value * 1000).toLocaleDateString() : null,
    // },
    // {
    //     Header: "Finalizado",
    //     accessor: (row) => {
    //         if(row.Actualizado){
    //             const idx = row.Actualizado.findIndex(
    //             (act) => act.Fecha_finalizado !== undefined
    //             );
    //             return idx >= 0 ? row.Actualizado[idx].Fecha_finalizado.seconds : null;
    //         }},
    //       Cell: ({ value }) =>
    //         value ? new Date(value * 1000).toLocaleDateString() : null,
    // },
    
]

export const columnsTalleres = [
    {
        Header: "ID SAP",
        accessor: "CodigoSAP"
    },
    {
        Header: "Nombre",
        accessor: "Titular",
    },
    {
        Header: "Razon social",
        accessor: "Taller",
    },
    {
        Header: "Cuit",
        accessor: "Cuit",
    },
    {
        Header: "Telefono",
        accessor: "Telefono",
    },
    {
        Header: "Direccion",
        accessor: "Direccion",
    },
    {
        Header: "Localidad",
        accessor: "Localidad",
    },
    {
        Header: "Provincia",
        accessor: "Provincia",
    },
    {
        Header: "Email",
        accessor: "Email",
    },
    {
        Header: "Horarios",
        accessor: "Horarios",
    },
    {
        Header: "Transporte",
        accessor: "Transporte",
    },
]

export const formatCurrency = (value) => {
    try {
      const formattedValue = "$" + value.toFixed(2);
      return formattedValue;
    } catch (error) {
    
      return "$0.00"; // Valor predeterminado en caso de error
    }
  };
  

export const columnsRepuestos = (setUpdate) => [
    {
        Header: "Articulo",
        accessor: "Articulo",
    },
    {
        Header: "Categoria",
        accessor: "Categoria",
    },
    {
        Header: "Tipo",
        accessor: "Tipo",
    },
    {
        Header: "Repuesto",
        accessor: "Codigo",
    },
    {
        Header: "Descripcion",
        accessor: "Descripcion",
    },
    {
        Header: "Precio taller",
        accessor: "PrecioTaller",
        Cell: ({ value }) => formatCurrency(value),
    },
    {
        Header: "Precio publico",
        accessor: (row) => {return(formatCurrency(row.PrecioTaller * 1.3))} 
        //accessor: "PrecioPublico",
        //Cell: ({ value }) => formatCurrency(value),|
    },
    {
        Header: "Ultimo aumento",
        accessor: "aplicado",
    },
    {
        Header: "Ultima Actualizacion",
        accessor: "FechaAct",
        Cell: ({value}) => {
            if (value && value.seconds) {
                return new Date(value.seconds * 1000).toLocaleString().split(",")[0];
            } else {
                return ""; // Otra acción alternativa si no se cumple la condición
            }
        }  
    },
    {
        Header: "Modificar",
        accessor: "modificar",
        Cell: ({ row }) => {
            const [row1, setRow] = useState()
            
            return (
                <div>
                    <button className="btn btn-outline-success" data-bs-toggle="modal" onClick={() => setRow(row.original)} data-bs-target="#Modal3">Nuevo margen</button>
                    <ModalPrecio rowdata={row1} setUpdate={setUpdate} />
                </div>
            )
        }
    },
    
]

export const columnsRetail = [
    {
        Header: "Cuit",
        accessor: "Cuit",
    },
    {
        Header: "Razon social",
        accessor: "RazonSocial",
    },
    {
        Header: "Nombre fantasia",
        accessor: "Nombre",
    },
    {
        Header: "Email",
        accessor: "Email",
    },
    {
        Header: "Domicilio",
        accessor: "Domicilio",
    },
    {
        Header: "Telefono",
        accessor: "Telefono",
    }, 
     
]

export const columnsRemitos = [
    {
        Header: "Devolucion",
        accessor: "DocNumSAP",
    },
    {
        Header: "Cuit",
        accessor: "Cuit",
    },
    {
        Header: "Nombre fantasia",
        accessor: "Nombre",
    },
    {
        Header: "Fecha",
        accessor: "Fecha",
        Cell: ({value}) => {return(new Date(value).toLocaleString().split(',')[0])}
    },
    // {
    //     Header: "Domicilio",
    //     accessor: "Domicilio",
    // },
    // {
    //     Header: "Telefono",
    //     accessor: "Telefono",
    // },
    {
        Header: "Remito",
        accessor: "Remito",
    },    
    // {
    //     Header: "Remito Salida",
    //     accessor: (row) => row.remitoSalida,
    // },     
    // {
    //     Header: "RyD",
    //     accessor: (row) => row.Garantia.conGarantia 
    // },
    // {
    //     Header: "N/C",
    //     accessor: (row) => row.Garantia.sinGarantia  
    // },
    // {
    //     Header : "Ult Fecha",
    //     accessor: (row) => row.Ult_Fecha 
    // },   
    {
        Header: "Productos",
        accessor: "Producto",
    },
    {
        Header: "Cantidad",
        accessor: "Cantidad",
    },
    {
        Header: "Trabajado",
        accessor: (row) => row.Trabajado ? row.Trabajado : 0,
        //accessor: "",
        //Cell: ({value}) => {console.log(value);return(value. ? value.trabajado : 0)}
    }, 
    {
        Header: "Pendiente",
        accessor: (row) => row.Trabajado ? row.Cantidad - row.Trabajado : row.Cantidad,
        //accessor: "",
        //Cell: ({value}) => {console.log(value);return(value. ? value.trabajado : 0)}
    } ,
    {
        Header : "Creado por :",
        accessor: (row) => row.Creator  //Creator
    }, 
]

export const UpdateChange = async(datosfila, estado) =>{
    
    await EditOrdenTallerState(datosfila, estado)
}


export const columnsOrdenesTaller = [
    {
        Header: "Fecha",
        accessor: "TimeStamp",
        Cell: ({value}) => {return(new Date(value * 1000).toLocaleString())}
    },
    {
        Header: "N° Orden",
        accessor: "iddoc"
    },
    {
        Header: "Tecnico",
        accessor: "Titular"
    },
    {
        Header: "Cliente",
        accessor: "Cliente"
    },
    {
        Header: "Cuit/Dni",
        accessor: "CuitDni_cli"
    },
    {
        Header: "Categoria",
        accessor: "Categoria"
    },
    // {
    //     Header: "Costo MDO",
    //     accessor: "Mdo",
    // },
    {
        Header: "Operacion",
        accessor: "Garantia",
        Cell: ({value}) => {if(value){return('RyD')}else{return('N/C')}}
    },
    {
        Header: "N° Remito",
        accessor: "NumRemito"
    },
    {
        Header: "Fecha de Compra",
        accessor: "FechaCompra"
    },
    {
        Header: "Articulo",
        accessor: "Articulo"
    },
    {
        Header: "Numero Serie",
        accessor: "Num_Serie"
    },
    {
        Header: "Falla",
        accessor: "Desperfecto"
    },
    {
        Header: "Observaciones",
        accessor: "Comentarios"
    },
    {
        Header: "Estado",
        accessor: "Estado"
    },
    {
        Header: "Accion",
        accessor: "UltEstado",
        Cell: ({ row }) => {
            const [row1, setRow] = useState()
            return (
                <div>
                    <div className="btn-group dropup">
                        <button style={{ border: "none", backgroundColor: row.original.UltEstado === 'Transporte' ? '#CCF998' : row.original.UltEstado === 'En curso'?'#5DADE2': row.original.UltEstado === 'Preparando'?'#F8C471':'white', color: 'black' }} type="button" className="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => setRow(row.original.Id)}>
                            {row.original.UltEstado}
                        </button>
                        <div className="dropdown-menu dropdown-menu-tabla " data-popper-placement="let-start">                               
                            {/*<button className="dropdown-item" onClick={() => UpdateStateChange(row.original, "Pendiente")}>Pendiente</button>*/}
                            {/* <button className="dropdown-item" onClick={() => UpdateStateChange(row.original, 'BajaDeSap')}>Descontado de SAP</button>
                            <button className="dropdown-item" onClick={() => UpdateStateChange(row.original, "Despachado")}>Despachado</button> */}
                            <button className="dropdown-item" onClick={() => UpdateChange(row.original, "Tranferido")}>Transferencia</button>
                            <button className="dropdown-item" onClick={() => UpdateChange(row.original, "Rechazado")}>Rechazar</button>
                            <button className="dropdown-item" onClick={() => UpdateChange(row.original, "Cancelado")}>Cancelar</button>
                            {/* <button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#Modal1">Reparacion Finalizada</button> */}

                        </div>
                        {/* <ModalFinalizado rowdata={row1} setUpdate={setUpdate} /> */}
                    </div>

                </div>
            )
        },
    },

    // {
    //     Header: "Repuestos",
    //     accessor: (row) => {
    //         if(row.Actualizado){
    //             const idx = row.Actualizado.findIndex(
    //             (act) => act.Fecha_repuestos !== undefined
    //             );
    //             return idx >= 0 ? row.Actualizado[idx].Fecha_repuestos.seconds : null;
    //         }},
    //       Cell: ({ value }) =>
    //         value ? new Date(value * 1000).toLocaleDateString() : null,
    // },
    // {
    //     Header: "Reparando",
    //     accessor: (row) => {
    //         if(row.Actualizado){
    //             const idx = row.Actualizado.findIndex(
    //             (act) => act.Fecha_reparando !== undefined
    //             );
    //             return idx >= 0 ? row.Actualizado[idx].Fecha_reparando.seconds : null;
    //         }},
    //       Cell: ({ value }) =>
    //         value ? new Date(value * 1000).toLocaleDateString() : null,
    // },
    // {
    //     Header: "Central",
    //     accessor: (row) => {
    //         if(row.Actualizado){
    //             const idx = row.Actualizado.findIndex(
    //             (act) => act.Fecha_encentral !== undefined
    //             );
    //             return idx >= 0 ? row.Actualizado[idx].Fecha_encentral.seconds : null;
    //         }},
    //       Cell: ({ value }) =>
    //         value ? new Date(value * 1000).toLocaleDateString() : null,
    // },
    // {
    //     Header: "Finalizado",
    //     accessor: (row) => {
    //         if(row.Actualizado){
    //             const idx = row.Actualizado.findIndex(
    //             (act) => act.Fecha_finalizado !== undefined
    //             );
    //             return idx >= 0 ? row.Actualizado[idx].Fecha_finalizado.seconds : null;
    //         }},
    //       Cell: ({ value }) =>
    //         value ? new Date(value * 1000).toLocaleDateString() : null,
    // },
]

export const UpdateStateChange = async(datosfila, estado, setUpdate) =>{
    
    await AddRepuestoState(datosfila, estado)
    setUpdate(true)
}

export const columnsPedidoRepuestos = (setUpdate) => [
    {
        Header: "Fecha",
        accessor: "FechaAct",
        Cell: ({value}) => {return(new Date(value).toLocaleString().split(',')[0])}
    },
    {
        Header: "N° Orden",
        accessor: "iddoc"
    },
    {
        Header: "Tecnico",
        accessor: "Taller"
    },
    {
        Header: "Cliente",
        accessor: "Cliente"
    },
    {
        Header: "Repuesto",
        accessor: "Articulo"
    },
    {
        Header: "Descripcion",
        accessor: "Descripcion"
    },
    {
        Header: "Producto",
        accessor: "Producto"
    },
    {
        Header: "Cantidad",
        accessor: "Cantidad"
    },
    {
        Header: "Garantia",
        accessor: "Garantia",
        Cell: ({value}) => {if(value){return('SI')}else{return('NO')}}
    },
    {
        Header: "Falla",
        accessor: "Falla"
    },
    {
        Header: "Transporte",
        accessor: "Transporte"
    },
    // {
    //     Header: "N° Factura",
    //     accessor: "NumFactura"
    // },
    // {
    //     Header: "Comercio",
    //     accessor: "RazonSocialCli"
    // },
    {
        Header:"Orden Taller",
        accessor:"OrdenTaller"
    },
    {
        Header:"Lote Despachante",
        accessor:"Lote"
    },
    {
        Header:"Despachado",
        accessor:"FDespachado"
    },
    {
        Header: "Estado",
        accessor: "UltEstado",
        Cell: ({ row }) => {
            const [row1, setRow] = useState()
            
            return (
                <div>
                    <div className="btn-group dropup">
                        <button style={{ border: "none", backgroundColor: row.original.UltEstado === 'Transporte' ? '#CCF998' : row.original.UltEstado === 'En curso'?'#5DADE2': row.original.UltEstado === 'Preparando'?'#F8C471':'white', color: 'black' }} type="button" className="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => setRow(row.original.Id)}>
                            {row.original.UltEstado}
                        </button>
                        <div className="dropdown-menu dropdown-menu-tabla " data-popper-placement="let-start">                               
                            {/*<button className="dropdown-item" onClick={() => UpdateStateChange(row.original, "Pendiente")}>Pendiente</button>*/}
                            <button className="dropdown-item" onClick={() => UpdateStateChange(row.original, 'BajaDeSap', setUpdate)}>Descontado de SAP</button>
                            <button className="dropdown-item" onClick={() => UpdateStateChange(row.original, "Despachado", setUpdate)}>Despachado</button>
                            <button className="dropdown-item" onClick={() => UpdateStateChange(row.original, "Transporte", setUpdate)}>En transporte</button>
                            <button className="dropdown-item" onClick={() => UpdateStateChange(row.original, "Autorizado", setUpdate)}>Autorizar</button>
                            <button className="dropdown-item" onClick={() => UpdateStateChange(row.original, "Cancelado", setUpdate)}>Cancelar</button>
                            <button className="dropdown-item" onClick={() => UpdateStateChange(row.original, "sinStock", setUpdate)}>Sin Stock</button>
                            {/* <button className="dropdown-item" onClick={() => UpdateStateChange(row.original, "Pendiente")}>Pendiente</button> */}
                            {/* <button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#Modal1">Reparacion Finalizada</button> */}

                        </div>
                        {/* <ModalFinalizado rowdata={row1} setUpdate={setUpdate} /> */}
                    </div>

                </div>
            )
        },
    },
    

]

export const columnsRepuestosCF = [
    // {
    //     Header: "Articulo",
    //     accessor: "Articulo",
    // },
    {
        Header: "Categoria",
        accessor: "Categoria",
    },
    {
        Header: "Tipo",
        accessor: "Tipo",
    },
    // {
    //     Header: "Mano de obra",
    //     accessor: "mdo",
    // },
    {
        Header: "Repuesto",
        accessor: "Codigo[0]",
    },
    {
        Header: "Descripcion",
        accessor: "Descripcion",
    },
    {
        Header: "Precio Publico",
        accessor: "PrecioPublico",
        Cell: ({ value }) => formatCurrency(value),
    },
    {
        Header: "Precio Taller",
        accessor: "PrecioTaller",
        Cell: ({ value }) => formatCurrency(value),
    },
    {
        Header: "Ultima Actualizacion",
        accessor: "Ult_Act",
    },

]

export const columnsRepuestosConfirmar = [
    {
        Header: "Fecha",
        accessor: "Fecha",
    },
    {
        Header: "Repuesto",
        accessor: "Articulo"
    },
    {
        Header: "Descripcion",
        accessor: "Descripcion"
    },
    {
        Header: "Precio Publico",
        accessor: "PrecioPublico",
    },
    {
        Header: "Precio Taller",
        accessor: "PrecioTaller",
    },
    {
        Header: "Cantidad",
        accessor: "cantidad",
    }
]

export const columnsRepuestosoperacion = [
    {
        Header: "Fecha",
        accessor: "Fecha",
    },
    {
        Header: "N° Orden",
        accessor: "Orden",
    },
    {
        Header: "Remito",
        accessor: "Remito",
    },
    {
        Header: "Articulo",
        accessor: "Articulo",
    },
    {
        Header: "Repuesto",
        accessor: "Repuesto",
    },
    {
        Header: "Operacion",
        accessor: "OperacionAlta",
        Cell: ({value}) => {if(value){return('Alta')}else{return('Baja')}}
    }
]