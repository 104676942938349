import React, {useState, useRef, useEffect} from 'react'
import {BarScanner} from "./BarScanner"
import { Barcode } from './barcodes'
import {EtiquetaDespacho} from './EtiquetaDespacho'
import { useReactToPrint } from 'react-to-print';
import impresora from '../images/impresora.png'
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { ModalConfirmacion } from './modalConfirmacion'
import {Actualizar} from './dbfirebase'
import {useAuth} from "../context/authcontext";

export const Despacho = () => {

    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState()
    const [repuestos, setRepuestos] = useState([])
    const [nuevoregistro, setNuevoRegistro] = useState(false)
    const [actualizado, setActualizado] = useState()
    const [uuid, setUUID] = useState()
    const [listado, setListado] = useState()
    const [state, setState] = useState()
    const [update, setUpdate] = useState([]);

    const ordersdata = useSelector(state => state.ordersInfo)
    
    const OrderRef = useRef(null)
    const {user} = useAuth()
    
    
    const handlePrint = useReactToPrint({
        content: () => OrderRef.current,
        pageStyle: `
        @page {
            size: 10cm 15cm; /* A4 landscape */
            margin: 0.5cm; /* Margen de 1cm en todos los lados */
        }`,
        },
    );

    // Función que muestra una confirmación al usuario
    // const mostrarConfirmacion = () => {
    //     // Muestra un cuadro de diálogo con un mensaje y botones de aceptar y cancelar
    //     const resultado = confirm("El lote previo no ha sido cerrado, ¿Estás seguro de continuar?, se perderan los datos registrados hasta el momento.");

    //     // Verifica la respuesta del usuario
    //     if (resultado) {
    //         // Si el usuario hace clic en "Aceptar"
    //         const Data = ordersdata.filter((item) => item.iddoc === data[data.length - 1].split('%')[0])
    //         const rep = Data[0].Repuestos.map(({ Estado, ...rest }) => rest).filter((item) => item.Articulo === data[data.length - 1].split('%')[1])
    //         setRepuestos(rep)
    //         setFilteredData(Data)
    //         // Aquí puedes colocar la lógica para continuar con la acción
    //     } else {
    //         // Si el usuario hace clic en "Cancelar" o cierra el cuadro de diálogo
    //         console.log('cancelado')
    //         // Aquí puedes colocar la lógica para manejar la cancelación
    //     }
    // }

    const confirmarAccion = () => {
        // Lógica a realizar al confirmar la acción
        toast.dismiss(); // Cierra la notificación
        // Aquí podrías realizar la acción que necesitas al confirmar
        handlePrint()
        const Data = ordersdata.filter((item) => item.iddoc === data[data.length - 1].split('%')[0])
        const rep = Data[0].Repuestos.map(({ Estado, ...rest }) => rest).filter((item) => item.id === data[data.length - 1].split('%')[1])
        
        setRepuestos([rep])
        setFilteredData(Data)
        setNuevoRegistro(true)
      };
    
      const cancelarAccion = () => {
        toast.dismiss(); // Cierra la notificación
        console.log('Acción cancelada');
      };
    
    const mostrarModalConfirmacion = () => {
        toast.info(<ModalConfirmacion 
                     mensaje="Esta accion cerrara los registros actuales, ¿Estás seguro de continuar?"
                     onConfirmar={confirmarAccion}
                     onCancel={cancelarAccion}
                   />, {
          position: 'top-right',
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          closeButton: false,
        });
      };

    useEffect(()=>{
        if (data[0]){
            const Data = ordersdata.filter((item) => item.iddoc === data[data.length - 1].split('%')[0])
            if (Data.length > 0) {
                if (state === 'despacho'){
                    if ((!filteredData) || (Data.length > 0 && Data[0].Taller === filteredData[0]?.Taller)){
                        const rep = Data[0].Repuestos.map(({ Estado, ...rest }) => rest).filter((item) => item.id?.toString() === data[data.length - 1].split('%')[1])
                        setRepuestos([...repuestos, rep])
                        setFilteredData(Data)
                        setUpdate([...update, rep[0]?.Articulo?data[data.length - 1]:''])
                        
                    }else{
                        mostrarModalConfirmacion()   
                    }
                }else{
                    if ((!filteredData) || (Data.length > 0)){
                        const rep = Data[0].Repuestos.map(({ Estado, ...rest }) => rest).filter((item) => item.id.toString() === data[data.length - 1].split('%')[1])
                        setRepuestos([...repuestos, rep])
                        setFilteredData(Data)
                        setUpdate([...update, rep[0]?.Articulo?data[data.length - 1]:''])
                    }
                }
            }
            // else{
            //     toast.error("Lectura no valida",{
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true
            //     })
            // }
        }    
    },[data, nuevoregistro])

    // useEffect(() => {
    //     // Limpiar el temporizador anterior al recibir nuevos datos
    //     clearTimeout(timer);
    
    //     // Iniciar un nuevo temporizador cuando se empiezan a recibir datos
    //     if (data.length > 0) {
    //       const newTimer = setTimeout(() => {
    //         // Procesar los datos después de 1 segundo
    //         procesarDatos();
    //       }, 2000);
    
    //       setTimer(newTimer);
    //     }
    // }, [data, nuevoregistro]);

    // const procesarDatos = () => {
    //     if (data[0]) {
    //       const Data = ordersdata.filter((item) => item.iddoc === data[data.length - 1].split('%')[0])[0];
    //       console.log(Data)
    //       if (Data) {
    //         if (state === 'despacho') {
    //           if ((!filteredData) || (Data.length > 0 && Data[0].Taller === filteredData[0]?.Taller)) {
    //             const rep = Data.Repuestos.map(({ Estado, ...rest }) => rest).filter((item) => item.Articulo === data[data.length - 1].split('%')[1]);
    //             setRepuestos([...repuestos, rep]);
    //             setFilteredData(Data);
    //           } else {
    //             mostrarModalConfirmacion();
    //           }
    //         } else {
    //           if ((!filteredData) || (Data.length > 0)) {
    //             const rep = Data.Repuestos.map(({ Estado, ...rest }) => rest).filter((item) => item.Articulo === data[data.length - 1].split('%')[1]);
    //             setRepuestos([...repuestos, rep]);
    //             setFilteredData(Data);
    //           }
    //         }
    //       }
    //     }
    // };
    
    
    const handleButtonClick = () => {
        ; // Cambiar el estado de estadorepuestos
       };

    const actualizar = async(barcodeValue) =>{
        if (repuestos.length > 0) {
            const actualizacion = await Actualizar(state, barcodeValue, user, setActualizado, uuid)
            // setEnviar(false)
            
            // toast.success("Actualizado",{
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true
            // })
            // setBarcodeValue('');
            
        }else{
            // toast.error("Debe seleccionar una accion.",{
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true
            // })
            //setBarcodeValue('');
        } 
    }

    const handleEliminarDato = (index) => {
        // Eliminar el dato de la lista
        const newRepuestos = [...repuestos];
        newRepuestos.splice(index, 1);
        setRepuestos(newRepuestos);
        const newUpdate = [...update];
        newUpdate.splice(index, 1);
        setUpdate(newUpdate)
      };

    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
    
    const cerrar = async() =>{
        try {
            for (const r of update) {
                // Aca debemos manejar cuando es una orden lo que debemos actualizar
              await actualizar(r);
              await delay(100);
              
            }
            toast.success("Actualizado",{
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true
            })
          } catch (error) {
            window.alert("Error con la actualizacion de uno o varios repuestos")
          }
        setData([])
        setRepuestos([])
        setFilteredData()
        setUpdate([])
    }
    console.log(data)
    return(
        <div className= 'row'>
            <h3 className='col-md-2 p-0' style={{ display: 'flex', width:'auto', justifyContent: window.innerWidth < 768 ? 'start' : 'end', alignItems: 'center' }}>Preparacion de repuestos</h3>
            <h3 className="p-2 d-flex justify-content-center">Escanear etiquetas</h3>
            <BarScanner setData={setData} setState={setState}/>
            <div className= 'row d-flex justify-content-center'style={{marginTop: '50px'}}>
                <div className="col-lg-6 d-flex justify-content-center">
                    
                
                <div style={{marginTop: '50px', width: '35rem'}} >
                {filteredData && state === "despacho"?
                    <>
                    <div className='col-12 d-flex justify-content-end d-print-none'>
                        <button style={{border:'none',backgroundColor: '#ffffff'}} onClick={()=>{handlePrint()}}>
                            <label style={{margin:'0 20px'}}>Imprimir</label>
                            <img src={impresora} width='50rem' height='50rem' alt=''/>
                        </button>
                    </div>
                    <div ref={OrderRef}>
                    <EtiquetaDespacho filteredData={filteredData} setUUID={setUUID}/>
                    </div>
                    </>:''
                }    
                </div>
                </div>
                <div className="col-lg-6" style={{marginTop: '50px', display: 'grid', gridTemplateRows: '0fr 1fr 0fr'}}>
                    <h3 >Listado de repuestos...</h3>
                    {/* <ul style={{marginTop: '50px'}}>
                        {filteredData && repuestos.map((d, index) => (
                            <li key={index}>{d[0].Articulo +' '+ d[0].Descripcion}</li>
                        ))}
                    </ul> */}
                    {repuestos && repuestos.length > 0 &&
                        <ul style={{marginTop: '50px'}}>
                            {filteredData && [...new Set(repuestos.map(item => item[0]?.Articulo + ' ' + item[0]?.Descripcion + ' ID:' + item[0]?.Ult_Act))]
                                .filter(l => l !== 'undefined undefined ID:undefined')
                                .map((l, index) =>(
                                    <li key={index}>
                                        <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                                            <span style={{ marginRight: '0.5rem'}}>{l}</span>
                                            <button onClick={() => handleEliminarDato(index)}
                                                type="button"
                                                style={{
                                                    backgroundColor: 'red',
                                                    color: '#fff',
                                                    borderRadius: '50%',
                                                    border: 'none',
                                                    width: '1rem',
                                                    height: '1rem',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    // position: 'absolute',
                                                    top: 0,
                                                    right: 0,
                                                    cursor: 'pointer'
                                                    }}>x
                                            </button>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    }
                <button className='btn btn-success' onClick={cerrar} style={{alignSelf: 'flex-end', width:'80%'}}>{state==='despacho'?'Cerrar lote':'Confirmar'}</button>
                </div>
            </div>
        </div>
    )
}